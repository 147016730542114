class ProductCardTemplateGloIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('ProductCardTemplateGlo before load');
	}

	afterLoad() {
		this.$el = $(this);
		const hasLoaded = this.$el.hasClass('loaded');

		let strengthLookup = JSON.parse(
			Commerce.getSessionStorage('strength-lookup-table')
		);

		let productWithUpdatedPrice;
		
		const updateCard = async () => {
			const componentObject = this.data;
			const { productSku } = componentObject;

			const loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';

			/*if(loggedIn){
				const productPricesRequest = await Commerce.getProductPrice(productSku);
				
				if (productPricesRequest && !productPricesRequest.errors) {
					productWithUpdatedPrice = productPricesRequest.data.products.items[0];
				}
			}*/

			Commerce.getProduct(productSku).then((response) => {
				const responseData = response.data;

				if (responseData && responseData.products.items.length > 0) {
					const productObj = responseData.products.items[0];

					// 	---------- | PDP URL | ----------
					// Set the url for the product's detail page
					// url_key is a graphQL field, value e.g. 'ice-cool'

					const urlKey = productObj.url_key;
					const urlPath = componentObject.productHeroUrl;
					let urlSuffix;

					urlKey && urlKey !== ''
						? (urlSuffix = `/${urlKey}`)
						: (urlSuffix = `?product_sku=${componentObject.productSku}`);

					componentObject.productHeroUrl = urlPath + urlSuffix;

					if (componentObject.ctaItems && componentObject.ctaItems.length > 0) {
						const firstCta = componentObject.ctaItems[0];
						if (firstCta.cta && firstCta.cta.url) {
							componentObject.productHeroUrl = firstCta.cta.url;
						}
					}

					componentObject.ctaItems = (
						componentObject.ctaItems || []
					).map((ctaItem) => {
						return {
							...ctaItem,
							cta: {
								...ctaItem.cta,
								url: componentObject.productHeroUrl,
							},
						};
					});
					// PRICE
					const productRegularValue = parseFloat(
						productObj.price_range.minimum_price.regular_price.value
					).toFixed(2);

					const productSaleValue = parseFloat(
						productObj.price_range.minimum_price.final_price.value
					).toFixed(2);

					const productCurrencyType =
						productObj.price_range.minimum_price.regular_price
							.currency;

					const productPrice = Commerce.priceFormatter(
						productRegularValue,
						productCurrencyType
					);
					componentObject.productPrice = productPrice;

					const productSalePrice = false && loggedIn ? 
						Commerce.priceFormatter(this.updateProductPrice(productWithUpdatedPrice), productCurrencyType)
						: Commerce.priceFormatter(productSaleValue, productCurrencyType);
					

					componentObject.productSalePrice = productSalePrice;

					const isProductOnSale =
						parseFloat(productSaleValue) <
						parseFloat(productRegularValue);

					// const priceString = isProductOnSale
					// 	? `<span>${productSalePrice}</span> <span class="slash">${productPrice}</span>`
					// 	: `<span>${productPrice}</span>`;

					const priceString = isProductOnSale
						? `<span>${productSalePrice}</span>`
						: `<span>${productPrice}</span>`;

					!componentObject.heading &&
						(componentObject.heading = priceString);

					// COMPONENT ANCHOR ID
					!componentObject.anchor &&
						(componentObject.anchor = `product${productObj.id}`);

					// PRODUCT DESCRIPTION
					!componentObject.description &&
						(componentObject.description =
							// productObj.short_description.html);
							'<p>Roasted tobacco with woody notes</p>');

					// PRODUCT NAME
					!componentObject.text &&
						(componentObject.text = productObj.display_name);
					componentObject.productName = productObj.name;

					// PRODUCT IMAGE
					!componentObject.image.altText &&
						(componentObject.image.altText =
							productObj.image.label) &&
						(componentObject.image.ctaTitle =
							productObj.image.label);
					!componentObject.image.imageSrc.desktop.src &&
						(componentObject.image.imageSrc.desktop.src =
							productObj.image.url);
					!componentObject.image.imageSrc.mobile.src &&
						(componentObject.image.imageSrc.mobile.src =
							productObj.small_image.url);

					if (!componentObject.familySku && productObj.__typename === 'ConfigurableProduct') {
						const variant = productObj.variants.find(el => el.product.sku === productSku);
						if (variant) {
							componentObject.productName = variant.product.name;
							componentObject.image.altText = variant.product.image.label;
							componentObject.image.ctaTitle = variant.product.image.label;
							componentObject.image.imageSrc.desktop.src = variant.product.image.url;
							componentObject.image.imageSrc.mobile.src = variant.product.small_image.url;
						}
					}
					// PRODUCT STRENGTHS

					if (productObj.__typename === 'SimpleProduct') {
						componentObject.productSku = productObj.sku;
						componentObject.productType = productObj.__typename;
						!componentObject.glo_scale_intensity &&
							(componentObject.glo_scale_intensity =
								productObj.glo_scale_intensity);
						if (strengthLookup) {
							strengthLookup.forEach((entry) => {
								if (
									componentObject.strength ===
									parseInt(entry.value, 10)
								) {
									componentObject.strength = entry.label
										.substr(0, entry.label.indexOf(' -'))
										.toLowerCase();
								}
							});
						}
					} else if (
						productObj.__typename === 'ConfigurableProduct'
					) {
						componentObject.productType = productObj.__typename;
						const productVariants = productObj.variants;
						const productStrengths = [];
						productVariants.forEach((variant) => {
							if (strengthLookup) {
								strengthLookup.forEach((lookup) => {
									if (
										variant.product.strength ===
										parseInt(lookup.value, 10)
									) {
										productStrengths.push({
											label: lookup.label
												.substr(
													0,
													lookup.label.indexOf(' -')
												)
												.toLowerCase(),
											sku: variant.product.sku,
										});
									}
								});
							}
						});

						componentObject.strengths = productStrengths;
					}

					// PRODUCT TYPE
					componentObject.productType = productObj.__typename;

					// STOCK STATUS
					componentObject.productStockStatus =
						productObj.stock_status;

					// PRODUCT BUNDLE
					if (productObj.__typename === 'BundleProduct') {
						componentObject.productBundle = productObj.items;
						/* eslint-disable-next-line no-console */
						console.log(
							'componentObject.productBundle',
							componentObject.productBundle
						);
					}

					// VARIANTS FOR CONFIGURABLE PRODUCTS
					if (productObj.__typename === 'ConfigurableProduct') {
						componentObject.variants = productObj.variants;
					}
					/*if(loggedIn) {
					
						this.updateProductPrice(componentObject,productWithUpdatedPrice,productCurrencyType);
						console.log("ora:", productSku,"---->",componentObject);
					}*/

					$(this).attr('data-model', JSON.stringify(componentObject));
				} else if (!this.classList.contains('author-mode')) {
					$(this).parent().remove();
				}
			});
		};

		if (!hasLoaded) {
			// make sure strength table is available first
			if (strengthLookup === null) {
				window.addEventListener('strength-table-available', () => {
					strengthLookup = JSON.parse(
						Commerce.getSessionStorage('strength-lookup-table')
					);
					updateCard();
				});
			} else updateCard();
		}
	}

	beforeUpdate() {
		// console.log('ProductCardTemplateGlo before update');
	}

	removePriceForConfiguratorePannelli(){
		if(document.querySelector("bat-productcard-gloithome[data-component-id='configuratore-pannelli']")){
			document.querySelector("bat-productcard-gloithome[data-component-id='configuratore-pannelli'] span.price").style.display="none"
		}
	}

	afterUpdate() {
		this.$el = $(this);
		const componentObject = this.data;

		const { productHeroUrl } = componentObject;

		this.$el
			.find('.bat-productcard-content-cta-list a')
			.attr('href', productHeroUrl);

		const showCard = () => {
			setTimeout(() => {
				this.$el.find('.bat-productcard').addClass('loaded');
			}, 250);
		};

		this.removePriceForConfiguratorePannelli();
		showCard();
	}

	unload() {
		// console.log('ProductCardTemplateGlo after unload');
	}

	updateProductPrice(new_item){
		let value;
		if (new_item.__typename == "ConfigurableProduct"){
			value = new_item.variants[0].product.price_range.minimum_price.final_price.value
		} else {
			value = new_item.price_range.minimum_price.final_price.value
		}
		return value.toFixed(2);
	}
}

!customElements.get('bat-productcard-gloithome') &&
	customElements.define(
		'bat-productcard-gloithome',
		ProductCardTemplateGloIT
	);
